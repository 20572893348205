/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';

#gantt_here{
  width: 100%;
  height: 100%;
}


/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");

* {
  font-family: "Roboto Condensed", sans-serif !important;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
.poppinsFamily {
  font-family: "Poppins" !important;
  color: #000;
  font-weight: 700 !important;
}
ion-tab-bar {
  padding-bottom: 8px;
}

ion-tab-button {
  --color: var(--ion-color-medium);
  --color-selected: var(--ion-color-primary);

  &::before {
    background-color: transparent;
    display: block;
    content: "";
    margin: 0 auto;
    width: 60px;
    height: 5.5px;
  }

  &.tab-selected::before {
    background-color: var(--ion-color-primary);
  }
}

.icon-circle[shape="circle"] {
  width: 40px;
  height: 40px;
  background: var(--ion-color-medium);
  border-radius: 100%;
  color: white;
}

.dashboard-icon {
  color: var(--ion-color-medium);
  font-size: 2.7rem;
}

.dashboard-icon-label {
  font-size: small;
  //margin-left: 10px;
}
.thumbnail {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.thumbnail {
  transition: transform 0.3s;
  cursor:pointer
}

.thumbnail:hover {
  transform: scale(1.1);
}

.icon-container {
  width: 87px;
  height: 87px;
  border-radius: 50px;
  border: 4px solid var(--ion-color-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}

.form-grid {
  // border: 1px dashed #808080;

  background-image: linear-gradient(to right, #808080 50%, transparent 50%),
    linear-gradient(to right, #808080 50%, transparent 50%),
    linear-gradient(to bottom, #808080 50%, transparent 50%),
    linear-gradient(to bottom, #808080 50%, transparent 50%);
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 20px 1px, 20px 1px, 1px 20px, 1px 20px;
  position: relative;
  z-index: 0;
  padding-bottom: 4rem;
}

.icon-overlay {
  position: absolute;
  left: 83%;
  transform: translateX(-50%);
  color: gray;
  margin-top: 1rem;
  font-size: 6rem;
  z-index: 1 !important;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  border: 4px solid var(--ion-color-medium);
  display: flex;
  background: white;
}

.icon-overlay:hover {
  position: absolute;
  left: 83%;
  transform: translateX(-50%);
  color: green;
  font-size: 6rem;
  z-index: 1 !important;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  border: 4px solid green;
  display: flex;
  background: white;
}

.icon-overlay:activa {
  position: absolute;
  left: 83%;
  transform: translateX(-50%);
  color: green;
  font-size: 6rem;
  z-index: 1 !important;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  border: 4px solid green;
  display: flex;
  background: white;
}

.login-form-align {
  bottom: 0 !important;
  position: sticky;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.logo-grid {
  display: flex;
  align-items: center;
  height: 42%;
  justify-content: center;
}

@media only screen and (max-height: 500px) {
  .logo-grid {
    width: 50%;
    height: 50%;
  }
  .login-form-align {
    //width: 50%;
    height: 50%;
  }
}

@media only screen and (max-height: 450px) {
  .logo-grid {
    width: 30%;
    height: 30%;
  }
  .login-form-align {
    //width: 50%;
    height: 50%;
  }
}
.badgeResponsive {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 780px) {
  .badgeResponsive {
    top: 7%;
    right: 30%;
    border: 2px solid white;
  }
}
@media only screen and (min-width: 1000px) {
  .badgeResponsive {
    top: 7%;
    right: 39%;
    border: 2px solid white;
  }
}
item-native {
  padding-inline-start: 0 !important;
}

.trash {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  font-size: 24px;
  right: 0;
  color: white;
  background: black;
  padding: 10px;
}
.font-label {
  font-weight: 700 !important;
}
.secondaryColor {
  color: #92949c !important;
}

/** FIX for Header overlay in Ios devices **/
.plt-ios.plt-mobile {
  ion-app > ion-tabs {
    top: 40px !important;
    height: calc(100% - 40px) !important;
  }
}

.cover-photo {
  height: 150px;
  background-size: cover;
  background-position: center;
  position: relative;
}
.timeline-avatar{
  border-radius: 50%;
    overflow: hidden;
    white-space: pre-line;
    width: 60px;
    height: 60px;
}
.profile-pic {
  position: absolute;
  /* bottom: 245px; */
  background-color: #f1f1f1;
  top: 51%;
  left: 85px;
  z-index: 1;
  transform: translateX(-50%);
  width: 118px;
  height: 118px;
  border: 5px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.profile-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cover-photo-vendor {
  height: 150px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.profile-pic-vendor {
  position: absolute;
  /* bottom: 245px; */
  background-color: #f1f1f1;
  top: 59%;
  left: 56px;
  z-index: 1;
  transform: translateX(-50%);
  width: 85px;
  height: 85px;
  border: 5px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.profile-pic-vendor img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cover-photo-form{
  height: 150px;
  background-size: cover;
  background-position: center;
  position: relative;
}
// .chips::after {
//   content: '';
//   position: absolute;
//   // top: 100%;
//   // left: calc(50% - 10px);
//   background: #dedede;
//   width: 20px;
//   height: 32px;
//   left:-19px;
//   /* The points are: (left top: x y, right top: x y, center bottom: x y) */
//   clip-path: polygon(100% 0, 0 50%, 100% 100%);
// }
.chips {
  background-color: #dedede;
  border-radius: 1rem;
  align-items: center;
  margin:0.2rem;
  cursor: pointer;
  max-width:33%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.ellipssis{
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
  text-overflow: ellipsis;
}
// ---------------------Alert Styling starts---------------
.successAlert{

  .alert-head{
    background-color: #58b858 !important;
    padding-top:0.55rem;
    padding-bottom:0.55rem;
    margin-bottom:0.55rem;
    color: white !important;
  }
  .alert-button{
    background-color: #808080;
    color: white;
    border-radius: 22px;
    padding: 6px 12px;
  }
}

.errorAlert{
  
  .alert-head{
    background-color: #ec4040 !important;
    padding-top:0.55rem;
    padding-bottom:0.55rem;
    margin-bottom:0.55rem;
    color: white !important;
  }
  .alert-button{
    background-color: #808080;
    color: white;
    border-radius: 22px;
    padding: 6px 12px;
  }
}

.warningAlert{
  
  .alert-head{
    background-color: #FFC107 !important;
    padding-top:0.55rem;
    padding-bottom:0.55rem;
    margin-bottom:0.55rem;
    color: white !important;
  }
  .alert-button{
    background-color: #808080;
    color: white;
    border-radius: 22px;
    padding: 6px 12px;
  }
}

// ---------------------Alert Styling ends---------------

ion-item.ion-touched.ion-invalid{
  border-bottom:solid 1px red;
}
